const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/pages/Index/IndexPage.vue'),
        meta: {
            back_path: '/',
            requiresAuth: false,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/add-patient',
        name: 'patient.add',
        component: () => import('@/pages/Index/AddPatient.vue'),
        meta: {
            back_path: '/',
            requiresAuth: false,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/options',
        name: 'options',
        component: () => import('@/pages/Index/OptionsPage.vue'),
        meta: {
            back_path: '/',
            requiresAuth: false,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/doctors',
        name: 'doctors.index',
        component: () => import('@/pages/Index/DoctorsPage.vue'),
        meta: {
            back_path: '/options',
            requiresAuth: false,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/orders',
        name: 'orders.index',
        component: () => import('@/pages/Index/OrdersPage.vue'),
        meta: {
            back_path: '/options',
            requiresAuth: false,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/services',
        name: 'services',
        component: () => import('@/pages/Index/ServicesPage.vue'),
        meta: {
            back_path: '/options',
            requiresAuth: false,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/doctors-by-service',
        name: 'doctors.by_service',
        component: () => import('@/pages/Index/DoctorsByService.vue'),
        meta: {
            back_path: '/services',
            requiresAuth: false,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/services-by-doctor',
        name: 'services.by_doctor',
        component: () => import('@/pages/Index/ServicesByDoctor.vue'),
        meta: {
            back_path: '/doctors',
            requiresAuth: false,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },
    {
        path: '/calendar',
        name: 'doctors.calendar',
        component: () => import('@/pages/Index/GeneralCalendar.vue'),
        meta: {
            back_path: '/services-by-doctor',
            requiresAuth: false,
            layout: 'content',
            title: 'Bosh sahifa',
        }
    },

]

export default routes