import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueTheMask from 'vue-the-mask'
import vueClickOutsideElement from 'vue-click-outside-element'
import store from './store'
import ax from 'axios'
import '/public/css/main.css';
import i18n from './i18n'
import FlagIcon from 'vue-flag-icon'

import {Icon} from 'leaflet'
import VueSweetalert2 from 'vue-sweetalert2';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen';
import 'leaflet/dist/leaflet.css';

window.axios = ax;
window.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

Vue.use(VueTheMask)
Vue.use(vueClickOutsideElement)
Vue.use(VueSweetalert2);
Vue.use(FlagIcon)


Vue.config.productionTip = false
import 'leaflet/dist/leaflet.css'
import 'sweetalert2/dist/sweetalert2.min.css';
// import {debounce} from "@/store/debounce";

delete Icon.Default.prototype._getIconUrl;

if (process.env.VUE_APP_API_TELEGRAM_ENABLED == 'true')
    window.telegram_data = window.Telegram.WebApp.initDataUnsafe
else
    window.telegram_data = {
        "query_id": "AAEQCrdCAgAAABAKt0JLqPfP",
        "user": {
            "id": 5414259216,
            "first_name": "Авазбек Неъматов",
            "last_name": "",
            "username": "avacoders",
            "language_code": "en",
            "allows_write_to_pm": true
        },
        "auth_date": "1725016017",
        "hash": "60ae7fced46001778df6cc86cee3d330f7cc45020eadd69ec1429ba9a4095b33"
    }

Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})
try {
    new Vue({
        vuetify,
        router,
        store,
        i18n,
        render: h => h(App)
    }).$mount('#app')
} catch (error) {
    console.error(error);
}
// Vue.config.errorHandler = debounce(function (err) {
//         // handle error
//         // `info` is a Vue-specific error info, e.g. which lifecycle hook
//         // the error was found in. Only available in 2.2.0+
//         axios.post("/api/error", {
//             err: err.stack,
//             type: 'registratura'
//         })
//     }, 500)


// let hours = 1;
// let now = new Date().getTime();
// let setupTime = localStorage.getItem('setupTime');
// let user = store.getters.registerer

// if (setupTime == null) {
//     localStorage.setItem('setupTime', now)
// } else {
//     if (now - setupTime > hours * 60 * 60 * 1000 && user) {
//         localStorage.clear();
//         localStorage.setItem('setupTime', now);
//         store.dispatch('clearStore').then(() => {
//             window.location.href = "/login";
//         });
//     }
// }


// axios.interceptors.response.use(function (response) {
//     return response
// }, function (error) {
//     if (error.response.status === 401 || error.response.status === 403) {
//         if (!['/not-auth', '/', '/login',
//             '/forgot-password', '/new-password', '/password-code', '/privacy-policy', '/terms-of-use', '/not-yet'
//         ].includes(router.currentRoute.path)) {
//             router.push('/login')
//         }
//     }
//     return Promise.reject(error)
// })