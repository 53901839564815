import axios from 'axios'

const state = {
    patient: {
        first_name: "",
        last_name: "",
        second_name: "",
        phone_number: "+998",
        phone_number2: "+998",
        region: {},
        district: {},
        mfy: {},
        street: "",
        flat: "",
    },
    patients: {},
    services: [],
    service_params: {
        name: "",
        page: 1,
    },
    doctor_params: {
        name: "",
        id: null,
        doctor_type: null,
        page: 1,
    },
    date: {
        date_to: "",
        date_from: "",
    },
    doctor: {},
    patient_names: [],
    patients_loading: false,
    patients_errors: {}
}

const getters = {
    patient: state => state.patient,
    patients: state => state.patients,
    patients_loading: state => state.patients_loading,
    patients_errors: state => state.patients_errors,
    patient_names: state => state.patient_names,
    service: state => state.service,
    doctor: state => state.doctor,
    service_params: state => state.service_params,
    doctor_params: state => state.doctor_params,
    date: state => state.date,
    services: state => state.services,

}

const mutations = {
    setPatients(state, patients) {
        state.patients = patients
    },
    setServiceParams(state, service_params) {
        state.service_params = service_params
    },
    setDoctorParams(state, doctor_params) {
        state.doctor_params = doctor_params
    },
    setDate(state, date) {
        state.date = date
    },
    setService(state, service) {
        state.service = service
    },
    setServices(state, services) {
        state.services = services
    },
    setDoctor(state, doctor) {
        state.doctor = doctor
    },
    setPatient(state, patient) {
        state.patient = patient
    },
    setPatientsLoading(state, loading) {
        state.patients_loading = loading
    },
    setPatientsErrors(state, errors) {
        state.patients_errors = errors
    },
    setPatientNames(state, patient_names) {
        state.patient_names = patient_names
    },

}

const actions = {
    async getPatient({commit}, id) {
        commit('setPatientsLoading', true)
        commit('setPatientsErrors', {})
        try {
            if (id) {

                let response = await axios.get(`/api/registerer/patients/${id}/show`)
                commit('setPatients', response.data)
            }

            commit('setPatientsLoading', false)

            return false

        } catch (error) {
            commit('setPatientsLoading', false)
            commit('setPatientsErrors', error.response.data.errors)
            return false
        }

    },
    async getPatientInfo({commit}, id) {
        commit('setPatientsLoading', true)
        commit('setPatientsErrors', {})
        try {
            if (id) {

                let response = await axios.get(`/api/registerer/patients/${id}/info`)
                commit('setPatient', response.data)
            }

            commit('setPatientsLoading', false)
            return false

        } catch (error) {
            commit('setPatientsLoading', false)
            commit('setPatientsErrors', error.response.data.errors)
            return false
        }
    },
    async getPatients({commit}, params) {
        commit('setPatientsLoading', true)
        commit('setPatientsErrors', {})
        try {

            let response = await axios.get(`/api/registerer/patients`, {params})
            commit('setPatients', response.data)
            commit('setPatientsLoading', false)
            return true

        } catch (error) {
            commit('setPatientsLoading', false)
            commit('setPatientsErrors', error.response.data.errors)
            return false
        }

    },

    async registerData({commit, getters}, params) {
        commit('setPatientsLoading', true)
        commit('setPatientsErrors', {})
        try {
            await axios.get(`/api/registerer/patients/store/data`, {params})
            let patient = getters.patient
            patient.data = true
            commit('setPatient', patient)
            return true

        } catch (error) {
            commit('setPatientsLoading', false)
            commit('setPatientsErrors', error.response.data.errors)
            return false
        }
    },
    async getPatientNames({commit}, params) {
        let response = await axios.get(`/api/registerer/patients/store/phone_number`, {params})
        if (response.data.ok) {
            commit('setPatientNames', response.data.data)
        }

        return true
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}