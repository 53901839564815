<template>
    <div>
        <v-navigation-drawer
                v-model="drawer"
                app
                clipped
                class="elevation-0"
                :width="expanded ? 300 : 70"
                color="white"
                elevation="0"
                style="border-right: 1px solid #e0e0e0"
        >
            <v-list-item :class="expanded ? 'pl-7' : 'row'" class=" py-8">
                <img src="/image/logo.png" :width="expanded ? 68 : 45" alt="" @click="reload">
            </v-list-item>


            <v-list dense nav>
                <v-list-item-group
                        color="primary"
                        v-model="navigations"
                >
                    <template v-for="(item, index) in items">
                        <v-list-item
                                class="py-2 px-4 ma-0"
                                :key="index"
                                link
                                :to="item.route"
                                :value="item.navigation"
                        >
                            <v-list-item-icon>
                                <v-icon size="24">{{ item.icon }}</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title style="font-size: 16px">{{ item.title }}</v-list-item-title>
                            </v-list-item-content>

                        </v-list-item>
                    </template>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
export default {
    name: "SideBar",
    props: {
        expanded: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            drawer: true,
            logout_dialog: false,
            navigations: null,
        };
    },
    watch: {
    },
  computed: {
    items(){
      return [
        {title: this.$t('components.sidebar.main'), icon: "mdi-information", route: `/register`, navigation: 'info'},
        {title: this.$t('components.sidebar.patients'), icon: "mdi-home", route: `/patients/${this.$route.params.id}/show`, navigation: 'info'},
        {title: this.$t('components.sidebar.orders'), icon: "mdi-doctor", route: `/patients/${this.$route.params.id}/orders/create`, navigation: 'order_create'},
      ]
    }
  },
    methods: {
        reload() {
            this.$router.push('/', () => window.location.reload())
        },
        logout() {
            this.$store.dispatch('logout').then((res) => {
                if (res)
                    this.$router.push('/login')
            })
        },
    }
}
</script>


<style scoped>
.text-color {
    color: #7F95A3 !important;
}

.text-size {
    font-size: 18px !important;
    line-height: 15px !important;
    padding: 4px 0 !important;
}

.logout_icon {
    font-size: 30px !important;
    margin: 0 10px 0 0 !important;
    padding: 0 !important;
    cursor: pointer;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50%;
}

.logout_icon:hover {
    background: #bbb !important;
    color: white !important;
}

.active {
    background: #01A1B7 !important;
}

.active-color {
    color: white !important;
}

.row {
    display: flex;
    justify-content: center;
    margin: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.flex-row {
    display: flex;
    justify-content: center;
    align-items: center !important;
}

.active-back {
    background: #01A1B7 !important;
    border-radius: 5px;
}

.mr0 {
    margin-right: 0 !important;
}
</style>