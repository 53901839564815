import axios from 'axios'

const state = {
    registerer: {},
    registerer_loading: false,
    registerer_errors: {},
    token: '',
    refresh_token: '',
    stats_doctors_filter: {
        doctor_types: [],
        doctor_id: null,
        region: null,
        district: null,
        drugs: [],
    },
    orders: [],
    orders_loading: false,
}

const getters = {
    registerer: state => state.registerer,
    registerer_loading: state => state.registerer_loading,
    registerer_errors: state => state.registerer_errors,
    token: state => state.token,
    refresh_token: state => state.refresh_token,
    stats_doctors_filter: state => state.stats_doctors_filter,
    orders: state => state.orders,
    orders_loading: state => state.orders_loading,
}

const mutations = {
    setRegisterer(state, registerer) {
        state.registerer = registerer
    },
    setRegistererLoading(state, loading) {
        state.registerer_loading = loading
    },
    setRegistererErrors(state, errors) {
        state.registerer_errors = errors
    },
    setToken(state, token) {
        state.token = token
    },
    setRefreshToken(state, token) {
        state.refresh_token = token
    },
    setStatsDoctorFilter(state, stats_doctors_filter) {
        state.stats_doctors_filter = stats_doctors_filter
    },
    setOrders(state, orders) {
        state.orders = orders
    },
    setOrdersLoading(state, orders_loading) {
        state.orders_loading = orders_loading
    },
}

const actions = {
    clearStore({commit}) {
        commit('setRegisterer', {})
        commit('setToken', '')
    },
    async checkLogin({commit}, data) {
        commit('setRegistererErrors', {})
        try {
            let response = await axios.post('/api/registerer/check-login', data)
            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setRegistererErrors', error.response.data.errors)
            return false
        }
    },
    async impersonate({commit}) {
        commit('setRegistererLoading', true)
        commit('setRegistererErrors', {})
        try {
            let response = await axios.get('/api/cashier/impersonate')
            commit('setRegistererLoading', false)
            if (response.data.ok) {
                commit('setRegisterer', response.data.user)
                commit('setToken', response.data.token)
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
                return true
            } else {
                return false
            }
        } catch (error) {
            commit('setRegistererLoading', false)
            if (error.response.data && error.response.data.errors)
                commit('setRegistererErrors', error.response.data.errors)
            return false
        }
    },
    async logout({commit}) {
        commit('setRegistererLoading', true)
        commit('setRegistererErrors', {})
        try {
            let response = await axios.post('/api/logout', {})
            commit('setRegistererLoading', false)
            commit('setToken', '')
            commit('setRegisterer', {})

            return response.data.ok
        } catch (error) {
            console.log(error);
            commit('setRegistererLoading', false)
            commit('setRegistererErrors', error.response.data.errors)
            commit('setToken', '')
            commit('setRegisterer', {})
            return false
        }
    },
    async check_me({commit}, data) {
        commit('setRegistererLoading', true)
        commit('setRegistererErrors', {})
        try {
            let response = await axios.post(`/api/telegram/${data.id}/me`, data)
            commit('setRegistererLoading', false)

            return response.data?.data
        } catch (error) {
            commit('setRegistererLoading', false)
            if (error.response.data && error.response.data.errors)
                commit('setRegistererErrors', error.response.data.errors)
            return false
        }
    },
    async login({commit}, data) {
        commit('setRegistererLoading', true)
        commit('setRegistererErrors', {})
        try {
            let response = await axios.post('/api/registerer/login', data)
            commit('setRegistererLoading', false)
            console.log(response.data)
            if (response.data.ok) {
                commit('setRegisterer', response.data.user)
                commit('setToken', response.data.token)
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
                return true
            } else {
                return false
            }
        } catch (error) {
            console.log(error);
            commit('setRegistererLoading', false)
            commit('setRegistererErrors', error.response.data.errors)
            return false
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}