<template>
    <v-app style="position: relative">
        <d-navbar style="z-index: 151; position:fixed; width: 100%; top: 0; background-color: #fff"/>
        <v-main style="margin-top: 68px;">
            <slot></slot>
        </v-main>
        <v-bottom-navigation v-model="navigation" color="primary" app>
            <v-btn x-large value="orders.index">
                <span>{{ $t("basics.orders") }}</span>

                <v-icon>mdi-list-box-outline</v-icon>
            </v-btn>

            <v-btn x-large value="options">
                <span>{{ $t("basics.create_order") }}</span>

                <v-icon>mdi-playlist-edit</v-icon>
            </v-btn>

            <v-btn x-large value="index">
                <span>{{ $t("basics.settings") }}</span>

                <v-icon>mdi-cog</v-icon>
            </v-btn>
        </v-bottom-navigation>
    </v-app>

</template>

<script>

export default {
    name: "LayoutContent",
    components: {
        DNavbar: () => import('@/components/DNavbar.vue')
    },
    data() {
        return {
            expanded: true,
            navigation: "index",
        }
    },
    mounted() {
        this.navigation = this.$route.name
    },
    watch: {
        navigation(name) {
            if (name !== this.$route.name)
                this.$router.push({name})
        }
    }
}
</script>

<style scoped>
.menu-icon {
    position: fixed;
    top: 20px;
    z-index: 153;
    cursor: pointer;
    transition: 0.2s;
}

.menu-icon:hover {
    opacity: 0.8;
}

.menu-icon:active {
    opacity: 0.6;
}


</style>
