<template>
    <component :is="resolveLayoutVariant">
        <v-slide-x-transition mode="out-in">
            <!--                <transition name="transition" mode="out-in" appear>-->
            <router-view></router-view>
            <!--                </transition>-->
        </v-slide-x-transition>
    </component>
</template>

<script>
import LayoutBlank from '@/layouts/LayoutBlank';
import LayoutContent from '@/layouts/LayoutContent';
import LayoutContentWithSidebar from '@/layouts/LayoutContentWithSidebar.vue';
import LayoutRegister from '@/layouts/LayoutRegister';

export default {
    name: 'App',

    components: {
        LayoutBlank,
        LayoutRegister,
        LayoutContent,
        LayoutContentWithSidebar,
        LayoutBase: () => import('@/layouts/LayoutBase'),
    },
    data(){
        return {
            lat: null,
            lng: null,
        }
    },
    async mounted() {
        let lat
        let lng
        if (navigator.geolocation) {
            await navigator.geolocation.getCurrentPosition(
                async (position) => {
                    lat = position.coords.latitude
                    lng = position.coords.longitude
                    if (window.telegram_data?.user?.id) {
                        let res = await this.$store.dispatch('check_me', {...window.telegram_data?.user, lat, lng})
                        if (res?.id) {
                            if (res.patient_id) {
                                this.$store.commit('setPatient', res.patient)
                            }
                        }

                    }
                },
                async (error) => {
                    console.error("Error getting location:", error);
                    if (window.telegram_data?.user?.id) {
                        let res = await this.$store.dispatch('check_me', {...window.telegram_data?.user, lat, lng})
                        if (res?.id) {
                            if (res.patient_id) {
                                this.$store.commit('setPatient', res.patient)
                            }
                        }

                    }
                }
            );
        } else {
            console.log("Geolocation is not supported by this browser.");
            if (window.telegram_data?.user?.id) {
                let res = await this.$store.dispatch('check_me', {...window.telegram_data?.user, lat, lng})
                if (res?.id) {
                    if (res.patient_id) {
                        this.$store.commit('setPatient', res.patient)
                    }
                }

            }
        }
        // if (!window.telegram_data?.user?.id)
        //   this.$router.push("/not-auth")


    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                document.title = to.meta.title || 'ONLINEMED.UZ';
            }
        },
    },
    methods: {},
    computed: {
        resolveLayoutVariant() {
            let route = this.$route
            let token = this.$store.getters.token
            let user = this.$store.getters.registerer
            if (route.meta.layout === 'base') return `layout-base`
            if (route.meta.layout === 'register') return `layout-register`
            if (route.meta.layout === 'content-with-sidebar') return `layout-content-with-sidebar`
            if (route.meta.layout === 'content') return `layout-content`
            if (route.meta.layout === 'blank' || !(token && user && user.viewed && user.accepted)) return 'layout-blank'

            return null
        }
    }
};
</script>
<style>

</style>